import React from 'react'
import { makeIcon } from 'design/components/Icons/Icons'

export const def = () => {
  return {
    id: 'americanExpressCard',
    category: 'payment',
    name: 'American Express Card',
    path: (
      <g fill="none" fillRule="evenodd">
        <path
          d="M0 3c0-1.657 1.348-3 3.007-3h59.986A3.01 3.01 0 0166 3v40c0 1.657-1.348 3-3.007 3H3.007A3.01 3.01 0 010 43V3z"
          fill="#016FD0"
        />
        <path
          d="M12.82 34.79V24.53h10.923l1.172 1.519 1.21-1.52h39.646v9.554s-1.037.697-2.236.707H41.582l-1.32-1.617v1.617h-4.33v-2.76s-.592.385-1.87.385h-1.474v2.375h-6.555l-1.17-1.552-1.189 1.552H12.821zM.066 16.796l2.463-5.712h4.26l1.397 3.2v-3.2h5.295l.832 2.312.807-2.312h23.77v1.162s1.249-1.162 3.302-1.162l7.712.026 1.374 3.158v-3.184h4.431l1.22 1.814v-1.814H61.4v10.26H56.93l-1.17-1.819v1.82h-6.51l-.654-1.618h-1.75l-.644 1.618h-4.415c-1.767 0-2.897-1.14-2.897-1.14v1.14h-6.657l-1.321-1.618v1.618H6.157l-.654-1.618H3.76l-.65 1.618H.066v-4.55z"
          fill="#FFF"
        />
        <path
          d="M3.4 12.349L.08 20.032H2.24l.613-1.539h3.564l.61 1.539h2.21L5.92 12.349H3.401zm1.23 1.788l1.086 2.688H3.54l1.09-2.688z"
          fill="#016FD0"
          fillRule="nonzero"
        />
        <path
          d="M9.467 20.03v-7.683l3.073.012 1.788 4.953 1.745-4.965h3.049v7.684H17.19v-5.662l-2.047 5.662H13.45l-2.052-5.662v5.662zM20.443 20.03v-7.683h6.301v1.719h-4.35v1.314h4.248v1.618h-4.248v1.364h4.35v1.669z"
          fill="#016FD0"
        />
        <path
          d="M27.862 12.349v7.683h1.931v-2.73h.813l2.315 2.73h2.36l-2.54-2.83a2.345 2.345 0 002.118-2.36c0-1.618-1.276-2.493-2.7-2.493h-4.297zm1.931 1.718H32c.53 0 .915.412.915.809 0 .51-.499.809-.886.809h-2.236v-1.618z"
          fill="#016FD0"
          fillRule="nonzero"
        />
        <path
          d="M37.619 20.03h-1.972v-7.683h1.972zM42.294 20.03h-.426c-2.059 0-3.31-1.613-3.31-3.81 0-2.25 1.237-3.873 3.837-3.873h2.135v1.82h-2.213c-1.055 0-1.802.82-1.802 2.072 0 1.488.854 2.113 2.084 2.113h.508l-.813 1.679z"
          fill="#016FD0"
        />
        <path
          d="M46.496 12.349l-3.323 7.683h2.163l.613-1.539h3.564l.61 1.539h2.21l-3.319-7.683h-2.518zm1.229 1.788l1.086 2.688h-2.176l1.09-2.688z"
          fill="#016FD0"
          fillRule="nonzero"
        />
        <path
          d="M52.558 20.03v-7.683h2.456l3.134 4.828v-4.828h1.931v7.684h-2.375l-3.215-4.954v4.954zM14.142 33.476v-7.683h6.301v1.718h-4.35v1.315h4.248v1.617h-4.248v1.365h4.35v1.668zM45.017 33.476v-7.683h6.302v1.718h-4.35v1.315h4.228v1.617h-4.228v1.365h4.35v1.668zM20.688 33.476l3.068-3.794-3.142-3.89h2.433l1.87 2.405 1.878-2.404h2.337l-3.1 3.841 3.074 3.842h-2.432l-1.816-2.366-1.773 2.366z"
          fill="#016FD0"
        />
        <path
          d="M29.336 25.794v7.683h1.982v-2.426h2.032c1.72 0 3.024-.908 3.024-2.673 0-1.462-1.023-2.584-2.773-2.584h-4.265zm1.982 1.738h2.14c.556 0 .953.338.953.884 0 .513-.395.885-.96.885h-2.133v-1.77zM37.212 25.793v7.683h1.931v-2.73h.813l2.315 2.73h2.36l-2.54-2.83a2.345 2.345 0 002.118-2.36c0-1.617-1.276-2.493-2.7-2.493h-4.297zm1.931 1.718h2.207c.53 0 .915.412.915.81 0 .51-.499.808-.886.808h-2.236v-1.618z"
          fill="#016FD0"
          fillRule="nonzero"
        />
        <path
          d="M52.213 33.476v-1.668h3.864c.572 0 .82-.307.82-.644 0-.323-.247-.65-.82-.65h-1.746c-1.518 0-2.363-.92-2.363-2.301 0-1.232.774-2.42 3.03-2.42h3.76l-.813 1.729h-3.252c-.622 0-.813.324-.813.634 0 .318.236.67.711.67h1.83c1.692 0 2.426.954 2.426 2.205 0 1.344-.818 2.445-2.519 2.445h-4.115zM59.3 33.476v-1.668h3.865c.571 0 .82-.307.82-.644 0-.323-.248-.65-.82-.65h-1.747c-1.518 0-2.363-.92-2.363-2.301 0-1.232.774-2.42 3.03-2.42h3.76l-.813 1.729H61.78c-.622 0-.813.324-.813.634 0 .318.236.67.711.67h1.83c1.692 0 2.426.954 2.426 2.205 0 1.344-.818 2.445-2.518 2.445H59.3z"
          fill="#016FD0"
        />
      </g>
    ),
    viewBox: '0 0 66 46',
  }
}

export const IconCcAmex = makeIcon(def())
export default IconCcAmex
